import React from "react";
import { TooltipHost, DirectionalHint, mergeStyleSets, DelayedRender, Callout, Text } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { getInitials } from '../../utils';
import CopyIcon from "../../components/icons/copy";

function PeopleShortCard (props) {
    const { item } = props;
    let fullname = `${item.firstname}${item.middlename ? ' ' + item.middlename + ' ' : ' '}${item.lastname}`;

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const copyToolTip = useId('copyToolTip');
    
    function copyEmial(email){
        navigator.clipboard.writeText(email);
        toggleIsCalloutVisible();
    }

    const styles = mergeStyleSets({
        button: {
          width: 130,
        },
        callout: {
          width: 'fit-content',
          padding: '10px',
          borederRadius: '5px',
        },
        text: {
            fontFamily: "'Gotham-light'",
            fontStyle: 'normal',
            fontWeight: '325',
            fontSize: '14px',
            lineHeight: '16px',
        }
    });

    return (
        <div className='result_item users'>
            <div className='item_title'>
                <div className='icon'>
                    {item.firstname && item.lastname &&
                        <div className='no_image'>{getInitials(fullname)}</div>
                    }
                </div>
                <div className='user_name'>
                    <span className='name'>{fullname}</span>
                </div>
            </div>
            <div className='data_row double'>
                <div className='data_item'>
                    <span className='title'>Job Title</span>
                    <span className='value'>
                        <span className="value_text">{item.jobTitle ? item.jobTitle : '-'}</span>
                    </span>
                </div>
                <div className='data_item'>
                    <span className='title'>Department</span>
                    <span className='value'>
                        <span className="value_text">{item.department ? item.department : '-'}</span>
                    </span>
                </div>
            </div>
            <div className='data_row double'>
                <div className='data_item'>
                    <span className='title'>User ID</span>
                    <span className='value'>
                        <span className="value_text">{item.rezidorEmployeeId ? item.rezidorEmployeeId : '-'}</span>
                    </span>
                </div>
                <div className='data_item'>
                    <span className='title'>Sub-department</span>
                    <span className='value'>
                        <span className="value_text">{item.subDepartment ? item.subDepartment : 'None'}</span>
                    </span>
                </div>
            </div>
            <div className='data_row double'>
                <div className='data_item'>
                    <span className='title'>Mobile Phone</span>
                    <span className='value'>
                        <span className="value_text">{item.mobilePhone ? item.mobilePhone : '-'}</span>
                    </span>
                </div>
                <div className='data_item'>
                    <span className='title'>Role</span>
                    <span className='value'>
                        <span className="value_text">{item.role ? item.role.replace(',','').trim() : '-'}</span>
                    </span>
                </div>
            </div>
            <div className='data_row'>
                <div className='data_item'>
                    <span className='title'>Email</span>
                    <span className='value'>
                        <TooltipHost
                            content={item?.email}
                            id={copyToolTip}
                            calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
                            className='fluent_tooltip'
                            directionalHint={DirectionalHint.bottomCenter}
                        >
                            <span className="value_text email">{item.email ? item.email : '-'}</span>
                        </TooltipHost>
                        <TooltipHost
                            content="Copy Email"
                            id={copyToolTip}
                            calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
                            className='fluent_tooltip'
                            directionalHint={DirectionalHint.topCenter}
                        >
                            <span className="copy_btn" onClick={() => copyEmial(item?.email)} id={buttonId}>
                                <CopyIcon />
                            </span>
                            {isCalloutVisible && (
                                <Callout 
                                    className={styles.callout} 
                                    target={`#${buttonId}`} 
                                    onDismiss={toggleIsCalloutVisible}
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    <DelayedRender>
                                        <Text variant="small" className={styles.text}>Email Copied to Clipboard</Text>
                                    </DelayedRender>
                                </Callout>
                            )}
                        </TooltipHost>
                    </span>
                </div>
            </div>
            <div className='data_row double'>
                <div className='data_item'>
                    <span className='title'>Business Unit Name</span>
                    <span className='value'>
                        <span className="value_text">{item.unitName ? item.unitName : '-'}</span>
                    </span>
                </div>
                <div className='data_item'>
                    <span className='title'>Business Unit</span>
                    <span className='value'>
                        <span className="value_text">{item.unit ? item.unit : '-'}</span>
                    </span>
                </div>
            </div>
        </div>
    )
} export  default  PeopleShortCard;