const apiScopes = process.env.REACT_APP_API_SCOPES;
const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_AUTHORITY;

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequestAPI = {
    scopes: [apiScopes]
};

export const adfsConfig = {
    authN: {
        url: 'https://signon.radissonhotels.com/adfs/oauth2/authorize',
        parameters: {
            client_id: process.env.REACT_APP_ADFS_CLIENT_ID,
            enabled: process.env.REACT_APP_ADFS_ENABLED,
            response_type: process.env.REACT_APP_ADFS_RESPONSE_TYPE,
            redirect_uri: `${window.location.origin}${process.env.REACT_APP_ADFS_REDIRECT_URI}`,
            resource: process.env.REACT_APP_ADFS_RESOURCE,
        }
    }
};